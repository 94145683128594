<div
    class="row justify-content-center"
    [class.dark-instructors-area]="themeService.isDark()"
>
    <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="single-instructor-member mb-30">
            <div class="member-image">
                <img src="assets/imagens/professores/gpt-professor1.jpg" alt="images">
            </div>
            <div class="member-content">
                <h3><a routerLink="/single-instructor">Professor 1</a></h3>
                <span>Cientista de Dados</span>
                <ul class="social">
                    <li><a href="#" class="facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                    <li><a href="#" class="twitter" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                    <li><a href="#" class="instagram" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                    <li><a href="#" class="linkedin" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="single-instructor-member mb-30">
            <div class="member-image">
                <img src="assets/imagens/professores/gpt-professor2.jpg" alt="images">
            </div>
            <div class="member-content">
                <h3><a routerLink="/single-instructor">Professor 2</a></h3>
                <span>Estatístico</span>

                <ul class="social">
                    <li><a href="#" class="facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                    <li><a href="#" class="twitter" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                    <li><a href="#" class="instagram" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                    <li><a href="#" class="linkedin" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="single-instructor-member mb-30">
            <div class="member-image">
                <img src="assets/imagens/professores/gpt-professor3.jpg" alt="images">
            </div>
            <div class="member-content">
                <h3><a routerLink="/single-instructor">Professor 3</a></h3>
                <span>Economista</span>

                <ul class="social">
                    <li><a href="#" class="facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                    <li><a href="#" class="twitter" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                    <li><a href="#" class="instagram" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                    <li><a href="#" class="linkedin" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                </ul>
            </div>
        </div>
    </div>
</div>
