<div
    class="motivation-featured-area bg-with-black-color"
    [class.dark-motivation-featured-area]="themeService.isDark()"
>
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-lg-3 col-sm-6">
                <div class="motivation-featured-item">
                    <div class="icon">
                        <i class="flaticon-checkmark"></i>
                    </div>
                    <h3>
                        <a routerLink="/cursos-lista">Aprenda as habilidades mais recentes</a>
                    </h3>
                    <p>Como análise de negócios, design gráfico, Python e muito mais.</p>
                    <a routerLink="/cursos-lista" class="featured-btn">Ver cursos +</a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="motivation-featured-item">
                    <div class="icon">
                        <i class="flaticon-login"></i>
                    </div>
                    <h3>
                        <a routerLink="/cursos-lista">128 Cursos</a>
                    </h3>
                    <p>Governança de TI: modelo de gestão, arquitetura e inovação.</p>
                    <a routerLink="/cursos-lista" class="featured-btn">Ver cursos +</a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="motivation-featured-item">
                    <div class="icon">
                        <i class="flaticon-credit-card"></i>
                    </div>
                    <h3>
                        <a routerLink="/cursos-lista">Preparatórios para Certificações</a>
                    </h3>
                    <p>FBB-100, FBB-110, FBB-120 e FBB-130.</p>
                    <a routerLink="/cursos-lista" class="featured-btn">Ver cursos +</a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="motivation-featured-item">
                    <div class="icon">
                        <i class="flaticon-verify"></i>
                    </div>
                    <h3>
                        <a routerLink="/cursos-lista">Aumente suas habilidades</a>
                    </h3>
                    <p>frameworks e bibliotecas populares de aprendizado de máquina, como TensorFlow, PyTorch e scikit-learn.</p>
                    <a routerLink="/cursos-lista" class="featured-btn">Ver cursos +</a>
                </div>
            </div>
        </div>
    </div>
</div>
