<section
    class="motivation-courses-area bg-FAF8F8 pt-100 pb-100"
    [class.dark-motivation-courses-area]="themeService.isDark()"
>
    <div class="container">
        <div class="section-title">
            <span class="sub-title">POPULAR COURSES</span>
            <h2>Nossos melhores cursos online</h2>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-motivation-courses-box mb-30">
                    <div class="courses-image">
                        <a routerLink="/curso-001" class="d-block"><img src="assets/imagens/cursos/curso1.png" alt="image"></a>
                        <div class="price">$1880</div>
                    </div>
                    <div class="courses-content">
                        <div class="courses-tag">
                            <a routerLink="/" class="d-block">VALIDAÇÃO</a>
                        </div>
                        <div class="course-author d-flex align-items-center justify-content-between">
                            <div class="author-box">
                                <img src="assets/imagens/professores/gpt-professor1.jpg" class="rounded-circle mr-2" alt="image">
                                <span>Professor 1</span>
                            </div>
                            <div class="courses-rating">
                                <div class="review-stars-rated">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <div class="rating-total">(0 Rating)</div>
                            </div>
                        </div>
                        <h3><a routerLink="/curso-001" class="d-inline-block">Validação de Modelos de Machine Learning</a></h3>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 74 Estudantes
                            </li>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 10 Modulos
                            </li>
                            <li class="courses-time">
                                <i class='bx bx-time-five'></i> 120 Horas
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-motivation-courses-box mb-30">
                    <div class="courses-image">
                        <a routerLink="error-404" class="d-block"><img src="assets/imagens/cursos/curso2.png" alt="image"></a>
                        <div class="price">$1024</div>
                    </div>
                    <div class="courses-content">
                        <div class="courses-tag">
                            <a routerLink="/" class="d-block">VALIDAÇÃO</a>
                        </div>
                        <div class="course-author d-flex align-items-center justify-content-between">
                            <div class="author-box">
                                <img src="assets/imagens/professores/gpt-professor2.jpg" class="rounded-circle mr-2" alt="image">
                                <span>Professor 2</span>
                            </div>
                            <div class="courses-rating">
                                <div class="review-stars-rated">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <div class="rating-total">(0 Rating)</div>
                            </div>
                        </div>
                        <h3><a routerLink="/error-404" class="d-inline-block">Validação de Modelos de Visão Computacional</a></h3>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 9 Estudantes
                            </li>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 20 Modulos
                            </li>
                            <li class="courses-time">
                                <i class='bx bx-time-five'></i> 60 Horas
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-motivation-courses-box mb-30">
                    <div class="courses-image">
                        <a routerLink="error-404" class="d-block"><img src="assets/imagens/cursos/curso3.png" alt="image"></a>
                        <div class="price">$1024</div>
                    </div>
                    <div class="courses-content">
                        <div class="courses-tag">
                            <a routerLink="/" class="d-block">VALIDAÇÃO</a>
                        </div>
                        <div class="course-author d-flex align-items-center justify-content-between">
                            <div class="author-box">
                                <img src="assets/imagens/professores/gpt-professor3.jpg" class="rounded-circle mr-2" alt="image">
                                <span>Professor 3</span>
                            </div>
                            <div class="courses-rating">
                                <div class="review-stars-rated">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <div class="rating-total">(0 Rating)</div>
                            </div>
                        </div>
                        <h3><a routerLink="/error-404" class="d-inline-block">Validação de Modelos de Inteligência Artificial</a></h3>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 91 Estudantes
                            </li>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 14 Modulos
                            </li>
                            <li class="courses-time">
                                <i class='bx bx-time-five'></i> 60 Horas
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-motivation-courses-box mb-30">
                    <div class="courses-image">
                        <a routerLink="error-404" class="d-block"><img src="assets/imagens/cursos/curso4.png" alt="image"></a>
                        <div class="price">$824</div>
                    </div>
                    <div class="courses-content">
                        <div class="courses-tag">
                            <a routerLink="/" class="d-block">VALIDAÇÃO</a>
                        </div>
                        <div class="course-author d-flex align-items-center justify-content-between">
                            <div class="author-box">
                                <img src="assets/imagens/professores/gpt-professor4.jpg" class="rounded-circle mr-2" alt="image">
                                <span>Professor 4</span>
                            </div>
                            <div class="courses-rating">
                                <div class="review-stars-rated">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <div class="rating-total">(0 Rating)</div>
                            </div>
                        </div>
                        <h3><a routerLink="error-404" class="d-inline-block">Validação de Modelos de Séries Temporais</a></h3>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 92 Estudantes
                            </li>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 13 Modulos
                            </li>
                            <li class="courses-time">
                                <i class='bx bx-time-five'></i> 60 Horas
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-motivation-courses-box mb-30">
                    <div class="courses-image">
                        <a routerLink="error-404" class="d-block"><img src="assets/imagens/cursos/curso5.png" alt="image"></a>
                        <div class="price">$1700</div>
                    </div>
                    <div class="courses-content">
                        <div class="courses-tag">
                            <a routerLink="/" class="d-block">GOVERNANÇA</a>
                        </div>
                        <div class="course-author d-flex align-items-center justify-content-between">
                            <div class="author-box">
                                <img src="assets/imagens/professores/gpt-professor5.jpg" class="rounded-circle mr-2" alt="image">
                                <span>Professor 5</span>
                            </div>
                            <div class="courses-rating">
                                <div class="review-stars-rated">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <div class="rating-total">(0 Rating)</div>
                            </div>
                        </div>
                        <h3><a routerLink="error-404" class="d-inline-block">Governança de Modelos de Inteligencia Artificial</a></h3>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 72 Estudantes
                            </li>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 18 Modulos
                            </li>
                            <li class="courses-time">
                                <i class='bx bx-time-five'></i> 60 Horas
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-motivation-courses-box mb-30">
                    <div class="courses-image">
                        <a routerLink="error-404" class="d-block"><img src="assets/imagens/cursos/curso6.png" alt="image"></a>
                        <div class="price">$1120</div>
                    </div>
                    <div class="courses-content">
                        <div class="courses-tag">
                            <a routerLink="/" class="d-block">GOVERNANÇA</a>
                        </div>
                        <div class="course-author d-flex align-items-center justify-content-between">
                            <div class="author-box">
                                <img src="assets/imagens/professores/gpt-professor6.jpg" class="rounded-circle mr-2" alt="image">
                                <span>Professor 6</span>
                            </div>
                            <div class="courses-rating">
                                <div class="review-stars-rated">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <div class="rating-total">(0 Rating)</div>
                            </div>
                        </div>
                        <h3><a routerLink="error-404" class="d-inline-block">Deploy de Modelos de Inteligência Artificial</a></h3>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 88 Estudantes
                            </li>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 10 Modulos
                            </li>
                            <li class="courses-time">
                                <i class='bx bx-time-five'></i> 60 Horas
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="courses-info-wrap">
                    <p>128+ Cursos disponíveis e atualizados <a routerLink="/cursos-lista">Veja todos os cursos.</a></p>
                </div>
            </div>
        </div>
    </div>
</section>
