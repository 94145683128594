<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Contate-nos</h3>
                    <ul class="contact-us-link">
                        <li>
                            <i class='bx bx-map'></i>
                            <a href="#" target="_blank">Brasília, DF</a>
                        </li>
                        <li>
                            <i class='bx bx-phone-call'></i>
                            <a href="tel:+11234567890">+55 (61) 99999-9999</a>
                        </li>
                        <li>
                            <i class='bx bx-envelope'></i>
                            <a href="mailto:contato&#64;bizq.com.br">contato&#64;bizq.com.br</a>
                        </li>
                    </ul>
                    <ul class="social-link">
                        <!-- <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li> -->
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <!-- <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> -->
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Documentos</h3>
                    <ul class="support-link">
                        <li><a routerLink="/">Privacidade</a></li>
                        <li><a routerLink="/">FAQ's</a></li>
                        <li><a routerLink="/">Suporte</a></li>
                        <li><a routerLink="/">Termos</a></li>
                        <li><a routerLink="/">Condições</a></li>
                        <li><a routerLink="/">Políticas</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Principais Links</h3>
                    <ul class="useful-link">
                        <li><a routerLink="/">Web Design</a></li>
                        <li><a routerLink="/">UI/UX Design</a></li>
                        <li><a routerLink="/">WP Development</a></li>
                        <li><a routerLink="/">App</a></li>
                        <li><a routerLink="/">Whitepaper</a></li>
                        <li><a routerLink="/">Administrador</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Newslatter</h3>
                    <div class="newsletter-box">
                        <p>Assine nossa Newslatter.</p>
                        <form class="newsletter-form">
                            <label>Seu email:</label>
                            <input type="email" class="input-newsletter" placeholder="E-mail" name="EMAIL">
                            <button type="submit">Assinar</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom-area">
        <div class="container">
            <div class="logo">
                <a routerLink="/" class="d-inline-block"><img src="assets/imagens/logo/logo-clara.png" alt="image"></a>
            </div>
            <p>© BizQ <a href="" target="_blank">Marca Registrada</a></p>
        </div>
    </div>
</footer>

<ngx-scrolltop></ngx-scrolltop>
