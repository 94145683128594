<app-header-style-three></app-header-style-three>

<div class="page-title-area page-title-style-two item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Início</a></li>
                <li>Cursos</li>
            </ul>
            <h2>Nossos Cursos</h2>
        </div>
    </div>
</div>

<div
    class="courses-area ptb-100"
    [class.dark-courses-area]="themeService.isDark()"
>
    <div class="container">
        <div class="courses-topbar">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-4">
                    <div class="topbar-result-count">
                        <p>Mostrando 1 – 6 of 25</p>
                    </div>
                </div>
                <div class="col-lg-8 col-md-8">
                    <div class="topbar-ordering-and-search">
                        <div class="row align-items-center">
                            <div class="col-lg-3 col-md-5 offset-lg-4 offset-md-1 col-sm-6">
                                <div class="topbar-ordering">
                                    <select class="form-select">
                                        <option>Popularidade</option>
                                        <option>Avaliação</option>
                                        <option>Mais recente</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-6 col-sm-6">
                                <div class="topbar-search">
                                    <form>
                                        <label><i class="bx bx-search"></i></label>
                                        <input type="text" class="input-search" placeholder="Procurar pelo Nome...">
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box mb-30">
                    <div class="courses-image">
                        <a routerLink="/curso-001" class="d-block"><img src="assets/imagens/cursos/curso1.png" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/curso-001" class="d-block">Validação</a>
                        </div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/imagens/professores/gpt-professor1.jpg" class="rounded-circle mr-2" alt="image">
                            <span>Professor 1</span>
                        </div>
                        <h3><a routerLink="/curso-001" class="d-inline-block">Validação de Modelos de Machine Learning</a></h3>
                        <div class="courses-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>
                            <div class="rating-total">
                                5.0 (1 rating)
                            </div>
                        </div>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 72 Estudantes
                            </li>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 6 Modulos
                            </li>
                            <li class="courses-price">
                                1880
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box mb-30">
                    <div class="courses-image">
                        <a routerLink="/error-404" class="d-block"><img src="assets/imagens/cursos/curso2.png" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/error-404" class="d-block">Validação</a>
                        </div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/imagens/professores/gpt-professor2.jpg" class="rounded-circle mr-2" alt="image">
                            <span>Perfil2</span>
                        </div>
                        <h3><a routerLink="/error-404" class="d-inline-block">Validação de Modelos de LLM (Large Language Model)</a></h3>
                        <div class="courses-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star-half'></i>
                            </div>
                            <div class="rating-total">
                                4.5 (2 rating)
                            </div>
                        </div>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 89 Estudantes
                            </li>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 10 Modulos
                            </li>
                            <li class="courses-price">
                                $1780
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box mb-30">
                    <div class="courses-image">
                        <a routerLink="/error-404" class="d-block"><img src="assets/imagens/cursos/curso3.png" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/error-404" class="d-block">TI</a>
                        </div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/imagens/professores/gpt-professor3.jpg" class="rounded-circle mr-2" alt="image">
                            <span>Professor 1</span>
                        </div>
                        <h3><a routerLink="/error-404" class="d-inline-block">Validação de Modelos de Intenligência Artificial</a></h3>
                        <div class="courses-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bx-star'></i>
                            </div>
                            <div class="rating-total">
                                4.0 (1 rating)
                            </div>
                        </div>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 58 Estudantes
                            </li>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 13 Modulos
                            </li>
                            <li class="courses-price">
                                $1150
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box mb-30">
                    <div class="courses-image">
                        <a routerLink="/error-404" class="d-block"><img src="assets/imagens/cursos/curso4.png" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/error-404" class="d-block">Validação</a>
                        </div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/imagens/professores/gpt-professor4.jpg" class="rounded-circle mr-2" alt="image">
                            <span>Professor1</span>
                        </div>
                        <h3><a routerLink="/error-404" class="d-inline-block">Modelos Econométricos</a></h3>
                        <div class="courses-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>
                            <div class="rating-total">
                                5.0 (1 rating)
                            </div>
                        </div>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 72 Estudantes
                            </li>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 6 Modulos
                            </li>
                            <li class="courses-price">
                                <span>$2000</span>
                                $1200
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box mb-30">
                    <div class="courses-image">
                        <a routerLink="/error-404" class="d-block"><img src="assets/imagens/cursos/curso5.png" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/error-404" class="d-block">Validação</a>
                        </div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/imagens/professores/gpt-professor5.jpg" class="rounded-circle mr-2" alt="image">
                            <span>Professor 2</span>
                        </div>
                        <h3><a routerLink="/error-404" class="d-inline-block">Validação de Modelos em R </a></h3>
                        <div class="courses-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>
                            <div class="rating-total">
                                5.0 (1 rating)
                            </div>
                        </div>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 72 Estudantes
                            </li>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 6 Modulos
                            </li>
                            <li class="courses-price">
                                $1780
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box mb-30">
                    <div class="courses-image">
                        <a routerLink="/error-404" class="d-block"><img src="assets/imagens/cursos/curso6.png" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/error-404" class="d-block">Negócios</a>
                        </div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/imagens/professores/gpt-professor6.jpg" class="rounded-circle mr-2" alt="image">
                            <span>Professor 3</span>
                        </div>
                        <h3><a routerLink="/error-404" class="d-inline-block">Negócios Empresariais</a></h3>
                        <div class="courses-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>
                            <div class="rating-total">
                                5.0 (1 rating)
                            </div>
                        </div>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 72 Estudantes
                            </li>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 6 Modelos
                            </li>
                            <li class="courses-price">
                                $500
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="pagination-area text-center">
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/cursos-lista" class="page-numbers">2</a>
                    <a routerLink="/cursos-lista" class="page-numbers">3</a>
                    <a routerLink="/cursos-lista" class="page-numbers">4</a>
                    <a routerLink="/cursos-lista" class="page-numbers">5</a>
                    <a routerLink="/cursos-lista" class="next page-numbers"><i class='bx bx-chevron-right'></i></a>
                </div>
            </div>
        </div>
    </div>
</div>
