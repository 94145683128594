<div
    class="education-course-banner-area"
    [class.dark-education-course-banner-area]="themeService.isDark()"
>
    <div class="container">
        <div class="education-course-banner-content">
            <h1>Cursos Essenciais Para Sua Carreira</h1>
            <div class="btn-box">
                <a routerLink="/cursos-lista" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">Nossos Cursos</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
            </div>
        </div>
    </div>
    <div class="education-categories-item technology">
        <div class="icon">
            <i class="bx bx-shape-triangle"></i>
        </div>
        <h3>
            <a routerLink="/cursos-lista">Validação de Modelos</a>
        </h3>
        <span>25 Cursos</span>
    </div>
    <div class="education-categories-item marketing">
        <div class="icon">
            <i class="bx bx-target-lock"></i>
        </div>
        <h3>
            <a routerLink="/cursos-lista">Governança de IA</a>
        </h3>
        <span>8 Cursos</span>
    </div>
    <div class="education-categories-item business">
        <div class="icon">
            <i class="bx bx-briefcase-alt-2"></i>
        </div>
        <h3>
            <a routerLink="/cursos-lista">Validação em Python</a>
        </h3>
        <span>10 Cursos</span>
    </div>
    <div class="education-categories-item health">
        <div class="icon">
            <i class="bx bx-first-aid"></i>
        </div>
        <h3>
            <a routerLink="/cursos-lista">Validação em R </a>
        </h3>
        <span>6 Cursos</span>
    </div>
    <div class="education-categories-item science">
        <div class="icon">
            <i class="bx bxs-drink"></i>
        </div>
        <h3>
            <a routerLink="/cursos-lista">Certificações</a>
        </h3>
        <span>5 Cursos</span>
    </div>
    <div class="education-categories-item graphics-design">
        <div class="icon">
            <i class="bx bx-layer"></i>
        </div>
        <h3>
            <a routerLink="/cursos-lista">Modelos Econométricos</a>
        </h3>
        <span>15 Cursos</span>
    </div>
</div>
