<app-header-style-two></app-header-style-two>

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Início</a></li>
                <li>Sobre</li>
            </ul>
            <h2>Sobre Nós</h2>
        </div>
    </div>
</div>

<div
    class="about-area ptb-100"
    [class.dark-about-area]="themeService.isDark()"
>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/imagens/sobrenos/sobrenos1.jpg" class="shadow" alt="image">
                    <img src="assets/imagens/sobrenos/sobrenos3.jpg" class="shadow" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">Sobre Nós</span>
                    <h2>Aprenda novas habilidades para seguir em frente em sua carreira</h2>
                    <h6>Podemos apoiar o fórum estudantil 24 horas por dia, 7 dias por semana, para estudantes nacionais e internacionais.</h6>
                    <p>Nosso objetivo é proporcionar uma experiência de aprendizado enriquecedora e acessível para todos. Oferecemos uma ampla variedade de cursos online que permitem aos alunos desenvolverem novas habilidades e avançarem em suas carreiras. Com uma equipe de suporte disponível 24/7, estamos aqui para ajudar tanto estudantes nacionais quanto internacionais a alcançarem seus objetivos educacionais.</p>
                    <p>A educação abrange tanto o ensino quanto a aprendizagem de conhecimento, conduta adequada e competência técnica.</p>
                    <div class="features-text">
                        <h5><i class='bx bx-planet'></i>Um lugar onde você pode realizar seu sonho</h5>
                        <p>A educação abrange tanto o ensino quanto a aprendizagem de conhecimento, conduta adequada e competência técnica.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="about-inner-area">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>128 online courses</h3>
                        <p>Inovações reais e uma experiência positiva para o cliente são a essência de uma comunicação bem-sucedida.</p>
                        <!-- <ul class="features-list">
                            <li><i class='bx bx-check'></i> Activate Listening</li>
                            <li><i class='bx bx-check'></i> Brilliant minds</li>
                            <li><i class='bx bx-check'></i> Better. Best. Wow!</li>
                            <li><i class='bx bx-check'></i> Branding it better!</li>
                        </ul> -->
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>Instrução especializada</h3>
                        <p>Nossos monitores são profissionais com experiência em grandes empresas, prontos para esclarecer suas dúvidas em cada curso.</p>
                        <!-- <ul class="features-list">
                            <li><i class='bx bx-check'></i> Creating. Results.</li>
                            <li><i class='bx bx-check'></i> Expect more</li>
                            <li><i class='bx bx-check'></i> Good thinking</li>
                            <li><i class='bx bx-check'></i> In real we trust</li>
                        </ul> -->
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                    <div class="about-text">
                        <h3>Suporte</h3>
                        <p>Estamos disponíveis em vários canais para atender suas necessidades da melhor forma possível. Não importa qual seja sua dúvida ou problema, estamos aqui para ajudar.</p>
                        <!-- <ul class="features-list">
                            <li><i class='bx bx-check'></i> Stay real. Always.</li>
                            <li><i class='bx bx-check'></i> We have you covered</li>
                            <li><i class='bx bx-check'></i> We turn heads</li>
                            <li><i class='bx bx-check'></i> Your brand, promoted</li>
                        </ul> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <app-our-mission></app-our-mission>

<div class="story-area ptb-100">
    <app-our-story></app-our-story>
</div>

<div class="funfacts-area">
    <div class="container">
        <div class="funfacts-inner">
            <app-funfacts></app-funfacts>
        </div>
    </div>
</div>

<div class="values-area ptb-100">
    <app-our-values></app-our-values>
</div> -->

<div class="instructor-area pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Faça conexões</span>
            <h2>Equipe de Instrutores</h2>
            <p> Cada um dos nossos instrutores passa por um rigoroso processo de seleção e treinamento para garantir que estejam preparados para oferecer a melhor experiência de aprendizado possível.</p>
        </div>
        <app-instructors-style-two></app-instructors-style-two>
    </div>
</div>

<!-- <div class="partner-area bg-color ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Company & Partners</h2>
        </div>
        <app-partner-style-one></app-partner-style-one>
    </div>
</div> -->

<!-- <div class="testimonials-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Testimonials</span>
            <h2>What Students Says</h2>
        </div>
        <app-feedback-style-two></app-feedback-style-two>
    </div>
</div> -->

<app-become-instructor-partner></app-become-instructor-partner>
