<app-header-style-two></app-header-style-two>

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Apresentação do Professor</li>
            </ul>
            <h2>Apresentação do Professor</h2>
        </div>
    </div>
</div>

<div
    class="instructor-details-area pt-100 pb-70"
    [class.dark-instructor-details-area]="themeService.isDark()"
>
    <div class="container">
        <div class="instructor-details-desc">
            <div class="row">
                <div class="col-lg-4 col-md-4">
                    <div class="instructor-details-sidebar">
                        <img src="assets/imagens/professores/gpt-professor1.jpg" alt="image">
                        <div class="instructor-details-meta">
                            <!-- <ul>
                                <li>
                                    <i class='bx bxs-group'></i>
                                    <span>Follower</span>
                                    <a routerLink="/">102</a>
                                </li>
                                <li>
                                    <i class='bx bx-group'></i>
                                    <span>Following</span>
                                    <a routerLink="/">81</a>
                                </li>
                            </ul> -->
                        </div>
                        <a routerLink="/contact" class="default-btn"><i class='bx bx-plus-circle icon-arrow before'></i><span class="label">Follow</span><i class="bx bx-plus-circle icon-arrow after"></i></a>
                        <ul class="social-link">
                            <li><a href="#" class="d-block facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="d-block twitter" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="d-block instagram" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="d-block linkedin" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" class="d-block pinterest" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-8 col-md-8">
                    <div class="instructor-details">
                        <h3>Professor 1</h3>
                        <span class="sub-title">Cientista de Dados</span>
                        <p>Meu nome é Professor 1 e sou professor de Ciência da Computação. Tenho um mestrado na área e uma paixão profunda por tecnologia e educação. Ao longo da minha carreira, tenho me dedicado a ensinar e pesquisar tópicos como algoritmos, inteligência artificial e desenvolvimento de software. Acredito que a educação é a chave para transformar vidas e estou comprometido em ajudar meus alunos a alcançar seu pleno potencial.</p>
                        <p>Gosto de criar um ambiente de aprendizado inovador e colaborativo, onde todos se sintam inspirados a explorar e crescer. Estou animado para compartilhar meu conhecimento e aprender com cada um de vocês. Vamos juntos construir um futuro tecnológico brilhante.</p>
                        <div class="instructor-details-info">
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <h3>Minha Formação</h3>
                                    <ul>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Universidade de Brasília - UNB</span>
                                            Mestre em Ciência da Computação
                                        </li>
                                        <!-- <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>University of Toronto</span>
                                            Bachelor in English
                                        </li> -->
                                    </ul>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                    <h3>Minha Experiência</h3>
                                    <ul>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>Banco X</span>
                                            2011-2024
                                        </li>
                                        <!-- <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>EnvyTheme</span>
                                            2010-2024
                                        </li> -->
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-title text-start">
            <h2>Cursos do Professor 1</h2>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box mb-30">
                    <div class="courses-image">
                        <a routerLink="/cursos-lista" class="d-block"><img src="assets/img/courses/courses1.jpg" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/" class="d-block">Validação</a>
                        </div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/imagens/professores/gpt-professor1.jpg" class="rounded-circle mr-2" alt="image">
                            <span>Professor 1</span>
                        </div>
                        <h3><a routerLink="/cursos-lista" class="d-inline-block">Validação de Modelos de Machine Learning</a></h3>
                        <div class="courses-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>
                            <div class="rating-total">
                                5.0 (1 rating)
                            </div>
                        </div>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 72 Estudantes
                            </li>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 6 lessons
                            </li>
                            <li class="courses-price">
                                1880
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box mb-30">
                    <div class="courses-image">
                        <a routerLink="/cursos-lista" class="d-block"><img src="assets/img/courses/courses2.jpg" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/" class="d-block">Validação</a>
                        </div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/imagens/professores/gpt-professor2.jpg" class="rounded-circle mr-2" alt="image">
                            <span>Perfil2</span>
                        </div>
                        <h3><a routerLink="/cursos-lista" class="d-inline-block">Validação de Modelos de Machine Learning</a></h3>
                        <div class="courses-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star-half'></i>
                            </div>
                            <div class="rating-total">
                                4.5 (2 rating)
                            </div>
                        </div>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 15 Estudantes
                            </li>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 10 Modulos
                            </li>
                            <li class="courses-price">
                                $1780
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box mb-30">
                    <div class="courses-image">
                        <a routerLink="/cursos-lista" class="d-block"><img src="assets/img/courses/courses3.jpg" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/" class="d-block">Desenvolvedor</a>
                        </div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/imagens/professores/gpt-professor3.jpg" class="rounded-circle mr-2" alt="image">
                            <span>Professor 1</span>
                        </div>
                        <h3><a routerLink="/cursos-lista" class="d-inline-block">Validação de Modelos em R.</a></h3>
                        <div class="courses-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bx-star'></i>
                            </div>
                            <div class="rating-total">
                                4.0 (1 rating)
                            </div>
                        </div>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 5 Estudantes
                            </li>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 13 Modulos
                            </li>
                            <li class="courses-price">
                                $1500
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
