<!-- Header -->
<header class="header-area">

    <!-- Top Header -->
    <div
        class="top-header-style-two"
        [class.dark-top-header-style-two]="themeService.isDark()"
    >
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-4">
                    <div class="top-header-logo">
                        <div
                            [class.d-none]="themeService.isDark()"
                        >
                            <a routerLink="/" class="d-inline-block">
                                <img src="assets/imagens/logo/logo-escura.png" alt="logo">
                            </a>
                        </div>
                        <div
                            class="d-none"
                            [class.d-block]="themeService.isDark()"
                        >
                            <a routerLink="/" class="d-inline-block">
                                <img src="assets/imagens/logo/logo-clara.png" alt="logo">
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8">
                    <ul class="top-header-contact-info">
                        <li>
                            <i class='bx bx-phone-call'></i>
                            <span>Contate</span>
                            <a href="tel:502464674">+55 (61) 99999-9999</a>
                        </li>
                        <li>
                            <i class='bx bx-map'></i>
                            <span>Nossa Localização</span>
                            <a href="#" target="_blank">Brasília, DF</a>
                        </li>
                        <li>
                            <i class='bx bx-envelope'></i>
                            <span>e-mail</span>
                            <a href="mailto:contato&#64;bizq.com.br">contato&#64;bizq.com.br</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <!-- Navbar -->
    <div
        class="navbar-area navbar-style-two"
        [ngClass]="{'sticky': isSticky}"
        [class.dark-navbar-area]="themeService.isDark()"
    >
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
                <a class="navbar-brand" routerLink="/">
                    <img src="assets/imagens/logo/logo-clara.png" alt="logo">
                </a>
                <button class="navbar-toggler" type="button" (click)="toggleClass()">
                    <span class="burger-menu">
                        <span class="top-bar"></span>
                        <span class="middle-bar"></span>
                        <span class="bottom-bar"></span>
                    </span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item"><a href="/" class="nav-link">Início</a></li>
                        <li class="nav-item"><a href="/cursos-lista" class="nav-link">Cursos</a></li>
                        <li class="nav-item"><a routerLink="/contato" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contato</a></li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</header>

<!-- Search Overlay -->
<div
    class="search-overlay"
    [class.active]="classApplied3"
    [class.dark-search-overlay]="themeService.isDark()"
>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-close" (click)="toggleClass3()">
                <span class="search-overlay-close-line"></span>
                <span class="search-overlay-close-line"></span>
            </div>
            <div class="search-overlay-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class='bx bx-search-alt'></i></button>
                </form>
            </div>
        </div>
    </div>
</div>
