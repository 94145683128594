<div
    class="container"
    [class.dark-make-connections]="themeService.isDark()"
>
    <div class="section-title text-start">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <span class="sub-title">FAÇA CONEXÕES</span>
                <h2>O que oferecemos para o crescimento</h2>
            </div>
            <div class="col-lg-6 col-md-12">
                <p>O que oferecemos para o crescimento Conecte-se com oportunidades únicas. Oferecemos soluções personalizadas para impulsionar seu crescimento. Nossa abordagem é focada em resultados e inovação.</p>
                <!-- <p class="bottom-zero">Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula. Quisque velit nisi, pretium ut lacinia in, elementum id enim.</p> -->
            </div>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-lg-4 col-sm-6">
            <div class="overview-connections-card mb-30">
                <h3><span>01)</span> Consultoria Exclusiva</h3>
                <p>Receba orientação especializada para alcançar seus objetivos. Nossa equipe está pronta para ajudar você a superar desafios e aproveitar novas oportunidades.</p>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6">
            <div class="overview-connections-card mb-30">
                <h3><span>02)</span> Alcance Seus Objetivos</h3>
                <p>Trabalhamos com você para definir metas claras e alcançáveis. Nossa metodologia comprovada garante que você atinja seus objetivos de forma eficiente.</p>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6">
            <div class="overview-connections-card mb-30">
                <h3><span>03)</span> Laboratório Digital</h3>
                <p>Explore novas tecnologias e estratégias digitais. Nosso laboratório é o espaço ideal para experimentar e implementar soluções inovadoras.</p>
            </div>
        </div>
    </div>
</div>
