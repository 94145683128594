<div
    class="login-area"
    [class.dark-login-area]="themeService.isDark()"
>
    <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0">
            <div class="login-image">
                <img src="assets/imagens/registro/login.jpg" alt="image">
            </div>
        </div>
        <div class="col-lg-6 col-md-12 p-0">
            <div class="login-content">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="login-form">
                            <div
                                class="logo"
                                [class.d-none]="themeService.isDark()"
                            >
                                <a routerLink="/">
                                    <img src="assets/imagens/logo/logo-escura.png" alt="image">
                                </a>
                            </div>
                            <div
                                class="logo d-none"
                                [class.d-block]="themeService.isDark()"
                            >
                                <a routerLink="/">
                                    <img src="assets/imagens/logo/logo-clara.png" alt="image">
                                </a>
                            </div>
                            <h3>Bem-vindo de volta</h3>
                            <p>Você é novo por aqui? <a routerLink="/register">Cadastar</a></p>
                            <form>
                                <div class="form-group">
                                    <input type="email" placeholder="Seu email" class="form-control">
                                </div>
                                <div class="form-group">
                                    <input type="password" placeholder="Sua Senha" class="form-control">
                                </div>
                                <button type="submit">Entrar</button>
                                <div class="forgot-password">
                                    <a routerLink="/">Esqueci minha senha?</a>
                                </div>
                                <div class="connect-with-social">
                                    <button type="submit" class="facebook"><i class='bx bxl-facebook'></i> Conecte com Facebook</button>
                                    <button type="submit" class="twitter"><i class='bx bxl-twitter'></i> Conecte com Twitter</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
