
<header class="header-area p-relative">

    <div class="top-header top-header-style-four">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-8">
                    <ul class="top-header-contact-info">
                        <li>
                            Telefone:
                            <a href="tel:+502464674">+55 (61) 99999-9999</a>
                        </li>
                    </ul>
                    <div class="top-header-social">
                        <span>Siga-nos:</span>
                        <a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a>
                        <a href="#" target="_blank"><i class='bx bxl-twitter'></i></a>
                        <a href="#" target="_blank"><i class='bx bxl-instagram'></i></a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4">
                    <ul class="top-header-login-register">
                        <li><a routerLink="/login"><i class='bx bx-log-in'></i> Entrar</a></li>
                        <li><a routerLink="/register"><i class='bx bx-log-in-circle'></i> Registre-se</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div
        class="navbar-area navbar-style-three"
        [ngClass]="{'sticky': isSticky}"
        [class.dark-navbar-area]="themeService.isDark()"
    >
        <div class="container-fluid">
            <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
                <a
                    class="navbar-brand"
                    routerLink="/"
                    [class.d-none]="themeService.isDark()"
                >
                    <img src="assets/imagens/logo/logo-escura.png" alt="logo">
                </a>
                <a
                    class="navbar-brand d-none"
                    routerLink="/"
                    [class.d-block]="themeService.isDark()"
                >
                    <img src="assets/imagens/logo/logo-clara.png" alt="logo">
                </a>
                <button class="navbar-toggler" type="button" (click)="toggleClass()">
                    <span class="burger-menu">
                        <span class="top-bar"></span>
                        <span class="middle-bar"></span>
                        <span class="bottom-bar"></span>
                    </span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item"><a href="/" class="d-inline-block">Início</a>
                        </li>
                        <li class="nav-item"><a href="javascript:void(0)" href="/cursos-lista" class="nav-link">Cursos</a>
                            <!-- <ul class="dropdown-menu">
                                <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Courses Category <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/courses-category-style-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Category Style 1</a></li>
                                        <li class="nav-item"><a routerLink="/courses-category-style-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Category Style 2</a></li>
                                        <li class="nav-item"><a routerLink="/courses-category-style-3" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Category Style 3</a></li>
                                    </ul>
                                </li>
                                <li class="nav-item"><a routerLink="/courses-list" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Courses List</a></li>
                                <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Courses Grid Style 1 <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/courses-2-columns-style-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">2 Columns</a></li>
                                        <li class="nav-item"><a routerLink="/courses-3-columns-style-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">3 Columns</a></li>
                                        <li class="nav-item"><a routerLink="/courses-4-columns-style-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">4 Columns Full Width</a></li>
                                    </ul>
                                </li>
                                <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Courses Grid Style 2 <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/courses-2-columns-style-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">2 Columns</a></li>
                                        <li class="nav-item"><a routerLink="/courses-3-columns-style-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">3 Columns</a></li>
                                        <li class="nav-item"><a routerLink="/courses-4-columns-style-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">4 Columns Full Width</a></li>
                                    </ul>
                                </li>
                                <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Courses Grid Style 3 <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/courses-2-columns-style-3" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">2 Columns</a></li>
                                        <li class="nav-item"><a routerLink="/courses-3-columns-style-3" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">3 Columns</a></li>
                                        <li class="nav-item"><a routerLink="/courses-4-columns-style-3" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">4 Columns Full Width</a></li>
                                    </ul>
                                </li>
                                <li class="nav-item"><a routerLink="/cursos-lista" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Courses Details</a></li>
                                <li class="nav-item"><a routerLink="/my-dashboard" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">My Dashboard</a></li>
                            </ul> -->
                        </li>
                        <li class="nav-item"><a href="javascript:void(0)" href="/about-style-1" class="nav-link">Sobre Nós</a>
                        </li>
                        <li class="nav-item"><a routerLink="/contato" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contato</a></li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>

</header>


<div
    class="search-overlay"
    [class.active]="classApplied3"
    [class.dark-search-overlay]="themeService.isDark()"
>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-close" (click)="toggleClass3()">
                <span class="search-overlay-close-line"></span>
                <span class="search-overlay-close-line"></span>
            </div>
            <div class="search-overlay-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class='bx bx-search-alt'></i></button>
                </form>
            </div>
        </div>
    </div>
</div>
