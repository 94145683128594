<app-header-style-two></app-header-style-two>

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Início</a></li>
                <li><a href="/">Cursos</a></li>
                <li>Validação de Modelos de Machine Learning</li>
            </ul>
            <h2>Cursos</h2>
        </div>
    </div>
</div>

<div
    class="courses-details-area pt-100 pb-70"
    [class.dark-courses-details-area]="themeService.isDark()"
>
    <div class="container">
        <div class="courses-details-header">
            <div class="row align-items-center">
                <div class="col-lg-8">
                    <div class="courses-title">
                        <h2>Validação de Modelos de Machine Learning</h2>
                        <p>O curso de Validação de Modelos de Machine Learning é projetado para quem já possui conhecimentos básicos em machine learning e deseja aprofundar-se nas técnicas de validação de modelos.</p>
                    </div>
                    <div class="courses-meta">
                        <ul>
                            <li>
                                <i class='bx bx-folder-open'></i>
                                <span>Categoria</span>
                                <a routerLink="/curso-001">Validação</a>
                            </li>
                            <!-- <li>
                                <i class='bx bx-group'></i>
                                <span>Students Enrolled</span>
                                <a routerLink="/error-404">813,454</a>
                            </li> -->
                            <li>
                                <i class='bx bx-calendar'></i>
                                <span>última Atualização</span>
                                <a routerLink="/curso-001">01/08/2024</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="courses-price">
                        <!-- <div class="courses-review">
                            <div class="review-stars">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>
                            <span class="reviews-total d-inline-block">(2 reviews)</span>
                        </div> -->
                        <div class="price">$1880</div>
                        <a routerLink="/curso-001" class="default-btn"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">Comprar Curso</span><i class="bx bx-paper-plane icon-arrow after"></i></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-8">
                <div class="courses-details-image text-center">
                    <img src="assets/imagens/cursos/curso1.png" alt="image">
                </div>
                <div class="courses-details-desc">
                    <h3>O que você vai aprender</h3>
                    <div class="why-you-learn">
                        <ul>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Fundamentos da Validação de Modelos
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Técnicas de Holdout e Split
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Validação Cruzada (Cross-Validation)
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Validação em Dados Agrupados
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Análise de Resultados de Validação
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Técnicas de Regularização
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Validação em Modelos de Ensemble
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Uso de Ferramentas e Bibliotecas
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Melhores Práticas e Casos de Uso
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Pipelines de Treinamento e Validação
                                </span>
                            </li>
                        </ul>
                    </div>
                    <h3>Pré-Requisitos</h3>
                    <ul class="requirements-list">
                        <li>Conhecimento básico em Machine Learning.</li>
                        <li>Experiência com Programação.</li>
                        <li>Noções de Estatística.</li>
                        <li>Experiência com Bibliotecas de Machine Learning.</li>
                    </ul>
                    <h3>Descrição</h3>

                    <p><strong>O Curso de Validação de Modelos de Machine Learning é ideal para profissionais que desejam aprofundar seus conhecimentos em técnicas avançadas de validação e garantir a robustez de seus modelos. </strong>
                    <p>Durante o curso, você aprenderá a entender os fundamentos da validação, compreendendo a importância e os conceitos básicos dessa prática essencial em machine learning.

                        Você aplicará técnicas de holdout e split, aprendendo a dividir seus dados de forma eficaz para validação inicial.Além disso, utilizará validação cruzada, implementando métodos como KFold, StratifiedKFold e Leave-One-Out para uma avaliação mais precisa dos modelos.

                    <p>O curso também abordará como trabalhar com dados agrupados, permitindo validar modelos em cenários onde os dados não são independentes. Você automatizará pipelines de treino e validação, garantindo consistência e reprodutibilidade no processo de validação.

                        A análise de resultados de validação será um ponto crucial, onde você interpretará métricas de performance como precisão, recall, F1-score e AUC-ROC. </p>
                    <p>Também implementará técnicas de regularização, utilizando métodos como Lasso e Ridge para evitar overfitting.

                        Além disso, aprenderá a validar modelos de ensemble, combinando múltiplos algoritmos para melhorar a performance. O curso utilizará ferramentas e bibliotecas populares, como Python e R, para implementar as técnicas de validação.

                        Por fim, você aplicará melhores práticas e estudará casos de uso reais, aprendendo com exemplos práticos e aplicando esses conhecimentos em seus próprios projetos. Este curso é projetado para cientistas de dados, analistas e profissionais de TI que já possuem uma base em machine learning e desejam aprimorar suas habilidades para criar modelos mais precisos e confiáveis.

                        Ao final do curso, você estará preparado para enfrentar desafios reais e melhorar significativamente a performance dos seus modelos de machine learning.</p>
                    <!-- <ul class="description-features-list">
                        <li>Introduction to Data and Data Science</li>
                        <li>Statistics and Excel</li>
                        <li>Database theory</li>
                        <li>SQL</li>
                        <li>Tableau</li>
                    </ul>
                    <p>These are the precise technical skills recruiters are looking for when hiring BI Analysts. And today, you have the chance of acquiring an invaluable advantage to get ahead of other candidates. This course will be the secret to your success. And your success is our success, so let’s make it happen!</p>
                    <p>Here are some more details of what you get with The Business Intelligence Analyst Course:</p>
                    <ul class="description-features-list">
                        <li><strong>Introduction to Data and Data Science</strong> – Make sense of terms like business intelligence, traditional and big data, traditional statistical methods, machine learning, predictive analytics, supervised learning, unsupervised learning, reinforcement learning, and many more;</li>
                        <li><strong>Statistics and Excel</strong> – Understand statistical testing and build a solid foundation. Modern software packages and programming languages are automating most of these activities, but this part of the course gives you something more valuable – critical thinking abilities;</li>
                        <li><strong>Database theory</strong> – Before you start using SQL, it is highly beneficial to learn about the underlying database theory and acquire an understanding of why databases are created and how they can help us manage data;</li>
                        <li><strong>SQL</strong> – when you can work with SQL, it means you don’t have to rely on others sending you data and executing queries for you. You can do that on your own. This allows you to be independent and dig deeper into the data to obtain the answers to questions that might improve the way your company does its business;</li>
                        <li><strong>Tableau</strong> – one of the most powerful and intuitive data visualization tools available out there. Almost all large companies use such tools to enhance their BI capabilities. Tableau is the #1 best-in-class solution that helps you create powerful charts and dashboards;</li>
                        <li>Learning a programming language is meaningless without putting it to use. That’s why we integrate SQL and Tableau, and perform several real-life Business Intelligence tasks;</li>
                    </ul>
                    <p><strong>Sounds amazing, right?</strong></p>
                    <p>Our courses are unique because our team works hard to:</p>
                    <ul class="description-features-list">
                        <li>Pre-script the entire content</li>
                        <li>Work with real-life examples</li>
                        <li>Provide easy to understand and complete explanation</li>
                        <li>Create beautiful and engaging animations</li>
                        <li>Prepare exercises, course notes, quizzes, and other materials that will enhance your course taking experience</li>
                        <li>Be there for you and provide support whenever necessary</li>
                    </ul>
                    <p>We love teaching and we are really excited about this journey. It will get your foot in the door of an exciting and rising profession. Don’t hesitate and subscribe today. The only regret you will have is that you didn’t find this course sooner!</p>
                    <h3>Who this course is for:</h3>
                    <ul class="audience-list">
                        <li>Beginners to programming and data science</li>
                        <li>Students eager to learn about job opportunities in the field of data science</li>
                        <li>Candidates willing to boost their resume by learning how to combine the knowledge of Statistics, SQL, and Tableau in a real-world working environment</li>
                        <li>SQL Programmers who want to develop business reasoning and apply their knowledge to the solution of various business tasks</li>
                        <li>People interested in a Business Intelligence Analyst career</li>
                    </ul> -->
                    <h3>Meet your instructors</h3>
                    <div class="courses-author">
                        <div class="author-profile-header"></div>
                        <div class="author-profile">
                            <div class="author-profile-title">
                                <img src="assets/imagens/professores/gpt-professor1.jpg" class="shadow-sm rounded-circle" alt="image">
                                <div class="author-profile-title-details d-flex justify-content-between">
                                    <div class="author-profile-details">
                                        <h4>Professor 1</h4>
                                        <span class="d-block">Cientista de Dados</span>
                                    </div>

                                    <div class="author-profile-raque-profile">
                                        <a routerLink="/single-instructor" class="d-inline-block">Veja o Perfil</a>
                                    </div>
                                </div>
                            </div>
                            <p>Meu nome é Professor 1 e sou professor de Ciência da Computação.</p>
                            <p>Tenho um doutorado na área e uma paixão profunda por tecnologia e educação. Ao longo da minha carreira, tenho me dedicado a ensinar e pesquisar tópicos como algoritmos, inteligência artificial e desenvolvimento de software.</p>
                        </div>
                    </div>
                    <!-- <div class="courses-review-comments">
                        <h3>3 Reviews</h3>
                        <div class="user-review">
                            <img src="assets/imagens/professores/gpt-professor1.jpg" alt="image">
                            <div class="review-rating">
                                <div class="review-stars">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <span class="d-inline-block">Professor 1</span>
                            </div>
                            <span class="d-block sub-comment">Excellent</span>
                            <p>Very well built theme, couldn't be happier with it. Can't wait for future updates to see what else they add in.</p>
                        </div>
                        <div class="user-review">
                            <img src="assets/imagens/professores/gpt-professor2.jpg" alt="image">
                            <div class="review-rating">
                                <div class="review-stars">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <span class="d-inline-block">Perfil2</span>
                            </div>
                            <span class="d-block sub-comment">Video Quality!</span>
                            <p>Was really easy to implement and they quickly answer my additional questions!</p>
                        </div>
                        <div class="user-review">
                            <img src="assets/imagens/professores/gpt-professor3.jpg" alt="image">
                            <div class="review-rating">
                                <div class="review-stars">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <span class="d-inline-block">Professor 3</span>
                            </div>
                            <span class="d-block sub-comment">Perfect Coding!</span>
                            <p>Stunning design, very dedicated crew who welcome new ideas suggested by customers, nice support.</p>
                        </div>
                    </div> -->
                </div>
                <div class="related-courses">
                    <h3>risk-curso</h3>
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="single-courses-box mb-30">
                                <div class="courses-image">
                                    <a routerLink="/curso-001" class="d-block"><img src="assets/imagens/cursos/curso1.png" alt="image"></a>
                                    <div class="courses-tag">
                                        <a routerLink="/curso-001" class="d-block">Validação</a>
                                    </div>
                                </div>
                                <div class="courses-content">
                                    <div class="course-author d-flex align-items-center">
                                        <img src="assets/imagens/professores/gpt-professor1.jpg" class="rounded-circle mr-2" alt="image">
                                        <span>Professor 1</span>
                                    </div>
                                    <h3><a routerLink="/curso-001" class="d-inline-block">Validação de Modelos de Machine Learning</a></h3>
                                    <div class="courses-rating">
                                        <div class="review-stars-rated">
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                        </div>
                                        <div class="rating-total">
                                            5.0 (1 rating)
                                        </div>
                                    </div>
                                </div>
                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 72 Estudantes
                                        </li>
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 6 Modulos
                                        </li>
                                        <li class="courses-price">
                                            1880
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="single-courses-box mb-30">
                                <div class="courses-image">
                                    <a routerLink="/error-404" class="d-block"><img src="assets/imagens/cursos/curso2.png" alt="image"></a>
                                    <div class="courses-tag">
                                        <a routerLink="/error-404" class="d-block">Validação</a>
                                    </div>
                                </div>
                                <div class="courses-content">
                                    <div class="course-author d-flex align-items-center">
                                        <img src="assets/imagens/professores/gpt-professor2.jpg" class="rounded-circle mr-2" alt="image">
                                        <span>Perfil2</span>
                                    </div>
                                    <h3><a routerLink="/error-404" class="d-inline-block">Validação de Modelos de Inteligância Artificial</a></h3>
                                    <div class="courses-rating">
                                        <div class="review-stars-rated">
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star-half'></i>
                                        </div>
                                        <div class="rating-total">
                                            4.5 (2 rating)
                                        </div>
                                    </div>
                                </div>
                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 15 Estudantes
                                        </li>
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 10 Modulos
                                        </li>
                                        <li class="courses-price">
                                            $1780
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="courses-sidebar-information">
                    <ul>
                        <li>
                            <span><i class='bx bx-group'></i> Estudantes:</span>
                            72
                        </li>
                        <li>
                            <span><i class='bx bx-time'></i> Tamanho:</span>
                            10 Semenas
                        </li>
                        <li>
                            <span><i class='bx bx-tachometer'></i> Esforço:</span>
                            2–5 Horas por Semana
                        </li>
                        <!-- <li>
                            <span><i class='bx bxs-institution'></i> Institution:</span>
                            <a href="#" target="_blank" class="d-inline-block">ABC</a>
                        </li> -->
                        <li>
                            <span><i class='bx bxs-graduation'></i> Tema:</span>
                            Validação
                        </li>
                        <li>
                            <span><i class='bx bx-atom'></i> Testes:</span>
                            Sim
                        </li>
                        <li>
                            <span><i class='bx bxs-badge-check'></i> Nível:</span>
                            Médio
                        </li>
                        <li>
                            <span><i class='bx bx-support'></i> Linguagem:</span>
                            Português
                        </li>
                        <!-- <li>
                            <span><i class='bx bx-text'></i> Video Subtitle:</span>
                            English
                        </li> -->
                        <li>
                            <span><i class='bx bx-certification'></i> Certificado:</span>
                            Sim
                        </li>
                    </ul>
                </div>
                <div class="courses-sidebar-syllabus">
                    <h3>Programa do Curso</h3>
                    <span class="chapter">Capítulo 1</span>
                    <h4>Modulos</h4>
                    <div class="courses-list">
                        <ul>
                            <li>
                                Introdução
                            </li>
                            <li>
                                <a routerLink="/error-404">
                                    <span class="number">1.</span> Aula 1 (14:37) <span class="free-lesson">Amostra</span>
                                </a>
                            </li>
                            <li>
                                Testes - 1
                            </li>
                            <li>
                                <span class="number">2.</span> Aula 2 (4:30)
                            </li>
                            <li>
                                Testes - 2
                            </li>
                            <li>
                                <span class="number">3.</span> Aula 3 (3:56)
                            </li>
                            <li>
                                Testes - 3
                            </li>
                            <li>
                                <span class="number">4.</span> Aula 4 (11:00)
                            </li>
                            <li>
                                <span class="number">5.</span> Aula 5 (8:12)
                            </li>
                            <li>
                                <span class="number">6.</span> Aula 6 (10:25)
                            </li>
                            <li>
                                Testes - 4
                            </li>
                            <li>
                                <span class="number">7.</span> Aula 7 (5:19)
                            </li>
                            <li>
                                <span class="number">8.</span> Aula 8 (16:07)
                            </li>
                            <li>
                                <span class="number">9.</span> Aula 9 (3:15)
                            </li>
                            <li>
                                Testes - 5
                            </li>
                        </ul>
                    </div>
                    <span class="chapter">Capítulo 2</span>
                    <h4>Questionário final</h4>
                    <div class="courses-list">
                        <ul>
                            <li>
                                Questionário final
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="courses-purchase-info">
                    <h4>Interessado neste curso para sua empresa ou equipe?</h4>
                    <p>Treine seus funcionários nos temas mais procurados com nossos treinamentos corporativos.</p>
                    <!-- <a routerLink="/error-404" class="d-inline-block">Compre Agora</a> -->
                    <a routerLink="/contato" class="d-inline-block">Solicitar informações</a>
                </div>
            </div>
        </div>
    </div>
</div>
