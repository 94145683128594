<div
    class="testimonials-slides"
    [class.dark-testimonials-slides]="themeService.isDark()"
>
    <owl-carousel-o [options]="testimonialsSlides">
        <ng-template carouselSlide>
            <div class="single-testimonials-item">
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                <div class="info">
                    <img src="assets/imagens/professores/gpt-professor1.jpg" class="shadow rounded-circle" alt="image">
                    <h3>John Smith</h3>
                    <span>Student</span>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-testimonials-item">
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                <div class="info">
                    <img src="assets/imagens/professores/gpt-professor2.jpg" class="shadow rounded-circle" alt="image">
                    <h3>Perfil2</h3>
                    <span>Student</span>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-testimonials-item">
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                <div class="info">
                    <img src="assets/imagens/professores/gpt-professor3.jpg" class="shadow rounded-circle" alt="image">
                    <h3>Professor 3</h3>
                    <span>Student</span>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-testimonials-item">
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                <div class="info">
                    <img src="assets/imagens/professores/gpt-professor4.jpg" class="shadow rounded-circle" alt="image">
                    <h3>Professor 1</h3>
                    <span>Student</span>
                </div>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>
