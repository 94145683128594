<div
    class="become-instructor-partner-area"
    [class.dark-become-instructor-partner-area]="themeService.isDark()"
>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="become-instructor-partner-content bg-color">
                    <h2>Seja Nosso Parceiro</h2>
                    <p>Você é um especialista apaixonado pelo que faz e deseja compartilhar seu conhecimento com o mundo? Junte-se a nós e torne-se um professor na nossa plataforma de cursos online!</p>
                    <a routerLink="/login" class="default-btn"><i class='bx bx-plus-circle icon-arrow before'></i><span class="label">Apply Now</span><i class="bx bx-plus-circle icon-arrow after"></i></a>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="become-instructor-partner-image bg-image1">
                    <img src="assets/img/become-instructor.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="become-instructor-partner-image bg-image2">
                    <img src="assets/imagens/sobrenos/sobrenos4.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="become-instructor-partner-content">
                    <h2>Capacitação Corporativa</h2>
                    <p>Nossos Cursos Corporativos são projetados para atender às necessidades específicas da sua organização, proporcionando uma abordagem personalizada e eficaz para o desenvolvimento de habilidades e competências.</p>
                    <a routerLink="/login" class="default-btn"><i class='bx bx-plus-circle icon-arrow before'></i><span class="label">Contate-nos</span><i class="bx bx-plus-circle icon-arrow after"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>
