<app-header-style-two></app-header-style-two>

<div class="page-title-area item-bg3">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>My Account</li>
                <li>Downloads</li>
            </ul>
            <h2>My Account</h2>
        </div>
    </div>
</div>

<div
    class="my-dashboard-area ptb-100"
    [class.dark-my-dashboard-area]="themeService.isDark()"
>
    <div class="container">
        <div class="myDashboard-navigation">
            <ul>
                <li><a routerLink="/my-dashboard"><i class='bx bxs-dashboard'></i> Dashboard</a></li>
                <li><a routerLink="/orders"><i class='bx bx-cart'></i> Orders</a></li>
                <li><a routerLink="/downloads" class="active"><i class='bx bx-download'></i> Downloads</a></li>
                <li><a routerLink="/edit-address"><i class='bx bx-home-alt'></i> Addresses</a></li>
                <li><a routerLink="/edit-account"><i class='bx bx-edit'></i> Account Details</a></li>
                <li><a routerLink="/"><i class='bx bx-log-out'></i> Logout</a></li>
            </ul>
        </div>
        <div class="myDashboard-content">
            <div class="downloads-table table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Item Name</th>
                            <th>Support Expires</th>
                            <th>Download</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><a routerLink="/cursos-lista">Novel Bunch</a></td>
                            <td>April 15, 2024</td>
                            <td><a routerLink="/downloads" class="downloads-button">Click Here</a></td>
                        </tr>
                        <tr>
                            <td><a routerLink="/cursos-lista">Book Chicks</a></td>
                            <td>Never</td>
                            <td><a routerLink="/downloads" class="downloads-button">Click Here</a></td>
                        </tr>
                        <tr>
                            <td><a routerLink="/cursos-lista">Book Divas</a></td>
                            <td>April 16, 2024</td>
                            <td><a routerLink="/downloads" class="downloads-button">Click Here</a></td>
                        </tr>
                        <tr>
                            <td><a routerLink="/cursos-lista">Book Smart</a></td>
                            <td>April 17, 2024</td>
                            <td><a routerLink="/downloads" class="downloads-button">Click Here</a></td>
                        </tr>
                        <tr>
                            <td><a routerLink="/cursos-lista">Book Broads</a></td>
                            <td>Never</td>
                            <td><a routerLink="/downloads" class="downloads-button">Click Here</a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
