<div class="become-box-area pb-70">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-12">
                <div class="become-box-item mb-30">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="become-box-image">
                                <img src="assets/img/motivation-course/become-1.png" alt="image">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="become-box-content">
                                <h3>Capacitação Corporativa</h3>
                                <p>Nossos Cursos Corporativos são projetados para atender às necessidades específicas da sua organização, proporcionando uma abordagem personalizada e eficaz para o desenvolvimento de habilidades e competências.</p>
                                <div class="btn-box">
                                    <a routerLink="/contact" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">Contate-nos</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="become-box-item with-gradient-color mb-30">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="become-box-image">
                                <img src="assets/img/motivation-course/become-2.png" alt="image">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="become-box-content">
                                <h3>Seja Nosso Parceiro</h3>
                                <p>Você é um especialista apaixonado pelo que faz e deseja compartilhar seu conhecimento com o mundo? Junte-se a nós e torne-se um professor na nossa plataforma de cursos online!</p>
                                <div class="btn-box">
                                    <a routerLink="/contact" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">Contate-nos</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
