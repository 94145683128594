<div
    class="new-comers-area ptb-100"
    [class.dark-new-comers-area]="themeService.isDark()"
>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="new-comers-image">
                    <img src="assets/imagens/sobrenos/sobrenos5.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="new-comers-content">
                    <span class="sub-title">Sobre nós</span>
                    <h3>Aprenda novas habilidades para avançar em sua carreira</h3>
                    <h4>Podemos apoiar o fórum estudantil 24 horas por dia, 7 dias por semana, para estudantes nacionais e internacionais.</h4>
                    <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae donec velit neque auctor sit amet aliquam vel ullamcorper sit amet ligula quisque velit nisi pretium ut lacinia in elementum id enim.</p>
                    <h5>Um lugar onde você pode realizar seu sonho</h5>
                    <p>Pellentesque in ipsum id orci porta dapibus. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Proin eget tortor risus. Lorem ipsum dolor sit amet consectetur adipiscing elit. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Donec rutrum congue leo eget malesuada.</p>
                    <div class="new-comers-btn">
                        <a routerLink="/about-style-1" class="default-btn">
                            <i class='bx bx-move-horizontal icon-arrow before'></i>
                            <span class="label">Saiba mais</span>
                            <i class="bx bx-move-horizontal icon-arrow after"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
